import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx";
import { graphql } from 'gatsby';
export const ProjectQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    banner: file(
      relativePath: { eq: "project/cardiac-signals-analysis/images/banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  ProjectQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Objetivo`}</h2>
    <p>{`Realizar la construcción de una GUI y un algoritmo para procesamiento y análisis
de señales cardiacas por medio de audio.`}</p>
    <h2>{`Contexto`}</h2>
    <p>{`Este fue mi proyecto final de séptimo semestre para la materia de señales y
sistemas, aquí puse en practica todo lo aprendido durante el transcurso del
semestre en esta carrera, lo cual comprende las técnicas básicas y mas populares
para análisis de señales y sistemas en los dominios del tiempo y la frecuencia,
como lo son: operaciones entre señales, consolación, y transformadas de Fourier
y Laplace.`}</p>
    <h2>{`Construcción del Proyecto`}</h2>
    <p>{`El algoritmo el cual podrán encontrar en GitHub permite hacer lo siguiente`}</p>
    <p><strong parentName="p">{`Dominio del Tiempo.`}</strong>{` Calcula los valores máximos, mínimos y media de:`}</p>
    <ul>
      <li parentName="ul">{`Duración del intervalo de un ciclo cardiaco y de los segmentos S1, S2, sístole
y diástole.`}</li>
      <li parentName="ul">{`Relación entre el intervalo sistólico y ciclo cardiaco`}</li>
      <li parentName="ul">{`Relación entre el intervalo diastólico y ciclo cardiaco`}</li>
      <li parentName="ul">{`Relación entre el intervalo sistólico y diastólico.`}</li>
      <li parentName="ul">{`Amplitud máxima y mínima en los segmentos S1, S2, sístole y diástole.`}</li>
      <li parentName="ul">{`Energía en el ciclo cardiaco, segmento S1, S2 sístole y diástole.`}</li>
      <li parentName="ul">{`Potencia en el ciclo cardiaco, segmento S1, S2 sístole y diástole.`}</li>
    </ul>
    <p><strong parentName="p">{`Dominio de la frecuencia.`}</strong>{` Calcula los valores máximos, mínimos y media de:`}</p>
    <ul>
      <li parentName="ul">{`Determinar el intervalo de las frecuencias en donde mayor energía se concentra
(Frecuencia inicial, frecuencia final, ancho del intervalo) en una grabación,
un ciclo cardiaco, segmento S1, S2, sístole y diástole.`}</li>
      <li parentName="ul">{`Calcular la energía en el intervalo de frecuencias.`}</li>
      <li parentName="ul">{`Calcular la potencia en el intervalo de frecuencias.`}</li>
      <li parentName="ul">{`Calcular la amplitud en el intervalo de frecuencia. ¿En qué frecuencia está la
amplitud máxima?`}</li>
      <li parentName="ul">{`Mostrar el Espectrograma.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      